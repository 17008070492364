import React, { Component } from "react";
import identityService from "../../services/identity.service";
import ConfirmDialog from "../general/dialog/confirm-modal-dialog.component";
import SuccessDialog from "../general/dialog/success-modal-dialog.component";

export default class AccountOtherScreen extends Component {
  constructor(props) {
    super(props);
    this.onCloseConfirmDialog = this.onCloseConfirmDialog.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.sendDeleteAccount = this.sendDeleteAccount.bind(this);
    this.onCloseSuccessDialog = this.onCloseSuccessDialog.bind(this);

    this.state = {
      showConfirmDialog: false,
      isLoading: false,
    };
  }

  sendDeleteAccount() {
    this.setState({
      isLoading: true,
    });
    identityService
      .delete()
      .then((response) => {
        this.setState({
          isLoading: false,
          showConfirmDialog: false,
          showSuccessDialog: true,
          successMessage:
            "Your request for deleting your account was successfully accepted. Confirmation email about this action and steps that needs to be finished for complete deletion of your account was sent. Please, check your inbox.",
        });
      })
      .catch((error) => {
        this.showWarningNotification(error.response.data.message);
      });
  }

  deleteAccount() {
    this.setState({
      showConfirmDialog: true,
      confirmMessage:
        "Do you really want to delete your account?",
    });
  }

  onCloseSuccessDialog() {
    this.setState({
      successMessage: "",
      showSuccessDialog: false,
    });
    window.location.href = "https://www.davidsimak.dev"
  }

  onCloseWarningDialog() {
    this.setState({
      showWarningDialog: false,
      errorMessage: "",
    });
  }

  onCloseConfirmDialog() {
    this.setState({
      confirmMessage: "",
      showConfirmDialog: false,
    });
  }
  render() {
    return (
      <div className="account_container">
        {this.state.isLoading ? <div id="loader" /> : null}

        <h2 className="container-title">Danger zone</h2>

        <div className="spacer_div_40"></div>

        <div className="section">
          <h3 className="section-title">Delete account</h3>
          <div className="spacer_div_20"></div>
          <p className="message">
            Please note that deleting your account will
            <br />
            <br />
            <ol>
              <li>
                Permanently delete you profile along with your authentication
              </li>
              <li>
                Permanently delete all your workspaces with their content including blogs, posts, notes, comments, files, etc
              </li>
              <li>
                Keep your content in workspaces of other users including your blogs, posts, notes, comments, files, etc
              </li>
              <li>
                Allow your username to become available to anyone
              </li>
            </ol>
            This action is unrecoverable, permanent and cannot be undone.
          </p>
          <div className="spacer_div_40"></div>
          <button className="setting-button" onClick={this.deleteAccount}>
            Delete your account
          </button>
        </div>

        {this.state.showConfirmDialog ? (
          <ConfirmDialog
            message={this.state.confirmMessage}
            onClose={this.onCloseConfirmDialog}
            onConfirm={this.sendDeleteAccount}
          />
        ) : null}

        {this.state.showSuccessDialog ? (
          <SuccessDialog
            message={this.state.successMessage}
            onClose={this.onCloseSuccessDialog}
          />
        ) : null}
      </div>
    );
  }
}
