import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";

import "../css/sidebar.css";

import LegalIcon from "../icons/scale-balance.svg";
import LogoutIcon from "../icons/logout.svg";
import InfoIcon from "../icons/information-outline.svg";
import CookieHelper from "../../../../CookieHelper";
import ChevronLeft from "../icons/chevron-left.svg";
import SidebarMenuItem from "./sidebar-menu-item.component";
import identitySidebarService from "../services/identity-sidebar.service";

export default class SidebarProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      profileUserName: "",
      profilePicture: null,
    };
  }

  componentDidMount() {
    this.setState({
      openMenu: this.props.openMenu,
      profileUserName: this.props.profileUserName,
      profilePicture: this.props.profilePicture,
    });
  }

  componentDidUpdate() {
    if (this.state.openMenu !== this.props.openMenu) {
      this.setState({
        openMenu: this.props.openMenu,
        profileUserName: this.props.profileUserName,
        profilePicture: this.props.profilePicture,
      });
    }
  }

  render() {
    const userName = this.state.profileUserName;
    const profilePicture = this.state.profilePicture;
    const openMenu = this.state.openMenu;
    const noOverlay = this.props.noOverlay;
    const customWidth = this.props.customWidth;

    return (
      <Menu
        isOpen={openMenu}
        menuClassName={
          (customWidth ? "section-full-width " : "") +
          "sidebar-slide-bottom-menu"
        }
        itemListClassName="sidebar-slide-bottom-menu-item-list"
        customBurgerIcon={false}
        onClose={() => {
          this.setState({
            openMenu: false,
          });
        }}
        noOverlay={noOverlay ? noOverlay : false}
        width={customWidth ? customWidth : "340px"}
      >
        <div
          className="sidebar-slide-menu-close-div"
          onClick={() => this.setState({ openMenu: false })}
        >
          <img src={ChevronLeft} className="sidebar-slide-menu-close-img" />
          {/* <p className="sidebar-slide-menu-close-text">Close</p> */}
        </div>
        <div style={{ height: "10px" }} />

        <div className="sidebar-profile-menu-item-list">
          <SidebarMenuItem
            icon={profilePicture}
            label={userName}
            customImgClass="sidebar-profile-photo"
            onMenuItemClick={() => {
              var url = "https://identity.davidsimak.dev/account";
              var newTab = window.open(url, "_blank");
              newTab.focus();
            }}
          />
          <hr className="sidebar-divider" />
          <SidebarMenuItem
            icon={InfoIcon}
            label="About"
            onMenuItemClick={() => {
              var url = "https://www.davidsimak.dev/projects/identity";
              var newTab = window.open(url, "_blank");
              newTab.focus();
            }}
          />
          <SidebarMenuItem
            icon={LegalIcon}
            label="Terms of service"
            onMenuItemClick={() => {
              var url = "https://www.davidsimak.dev/legal/terms-of-service";
              var newTab = window.open(url, "_blank");
              newTab.focus();
            }}
          />
          <SidebarMenuItem
            icon={LegalIcon}
            label="Privacy policy"
            onMenuItemClick={() => {
              var url = "https://www.davidsimak.dev/legal/privacy-policy";
              var newTab = window.open(url, "_blank");
              newTab.focus();
            }}
          />
          <hr className="sidebar-divider" />
          <SidebarMenuItem
            icon={LogoutIcon}
            label="Logout"
            onMenuItemClick={() => {
              identitySidebarService.logoutUser().finally(() => {
                CookieHelper.remove();
                window.location.href = "/auth/login";
              });
            }}
          />
        </div>
      </Menu>
    );
  }
}
