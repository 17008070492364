import React, { Component } from "react";
import identityService from "../../services/identity.service";
import ConfirmDialog from "../general/dialog/confirm-modal-dialog.component";
import SuccessDialog from "../general/dialog/success-modal-dialog.component";
import WarningDialog from "../general/dialog/warning-modal-dialog.component";
import reportVisitService from "../../reporting-visit/report-visit.service";
import Footer from "../general/footer/component/footer.component";
import AppIcon from "../../icons/app-icon-full.png";

export default class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.sendDeleteAccount = this.sendDeleteAccount.bind(this);
    this.confirmDeleteAccount = this.confirmDeleteAccount.bind(this);
    this.onCloseSuccessDialog = this.onCloseSuccessDialog.bind(this);
    this.onCloseConfirmDialog = this.onCloseConfirmDialog.bind(this);
    this.onCloseWarningDialog = this.onCloseWarningDialog.bind(this);

    this.state = {
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      showConfirmDialog: false,
      showSuccessDialog: false,
      showWarningDialog: false,
      pathVariable: "",
    };
  }

  componentDidMount() {
    document.title = "Identity | Delete your account";
    const path = window.location.pathname;
    reportVisitService.report(path, document.title);

    const pathVariableSplit = path.split("/");
    const pathVariable = pathVariableSplit[pathVariableSplit.length - 1];

    this.setState({
      pathVariable: pathVariable,
    });
  }

  sendDeleteAccount() {
    this.setState({
      isLoading: true,
      showConfirmDialog: false
    });
    identityService
      .deleteAccount(this.state.pathVariable)
      .then((response) => {
        this.setState({
          successMessage: "Your account was successfully deleted",
          errorMassage: "",
          showSuccessDialog: true,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          successMessage: "",
          showWarningDialog: true,
          isLoading: false,
          errorMessage:
            "Something went wrong during deleting your account. Please click on right link in email or contact support on support@davidsimak.dev",
        });
      });
  }

  onCloseWarningDialog() {
    this.setState({
      errorMessage: "",
      showWarningDialog: false,
    });
  }

  onCloseSuccessDialog() {
    this.setState({
      successMessage: "",
      showSuccessDialog: false,
    });
  }

  onCloseConfirmDialog() {
    this.setState({
      showConfirmDialog: false,
    });
  }

  confirmDeleteAccount() {
    this.setState({
      showConfirmDialog: true
    })
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? <div id="loader" /> : null}

        <div className="auth-app-header">
          <img className="auth-app-icon" src={AppIcon} />
          <h1 className="auth-app-title">Identity</h1>
        </div>

        <div id="page_content_wrapper" className="page_content_wrapper">
          <div className="auth_form">
            <h2 className="title_label">Deleting account</h2>
            <div className="spacer_div_40"></div>
            <p className="message">
              Please note that deleting your account will
              <br />
              <br />
              <ol>
                <li>
                  Permanently delete you profile along with your authentication
                </li>
                <li>
                  Permanently delete all your workspaces with their content
                  including blogs, posts, notes, comments, files, etc
                </li>
                <li>
                  Keep your content in workspaces of other users including your
                  blogs, posts, notes, comments, files, etc
                </li>
                <li>Allow your username to become available to anyone</li>
              </ol>
              This action is unrecoverable, permanent and cannot be undone.
            </p>
            <div className="spacer_div_40"></div>
            <button className="auth_button" onClick={this.confirmDeleteAccount}>
              Delete account
            </button>

            {this.state.showWarningDialog ? (
              <WarningDialog
                message={this.state.errorMessage}
                onClose={this.onCloseWarningDialog}
              />
            ) : null}

            {this.state.showSuccessDialog ? (
              <SuccessDialog
                message={this.state.successMessage}
                onClose={this.onCloseSuccessDialog}
              />
            ) : null}

            {this.state.showConfirmDialog ? (
              <ConfirmDialog
                message="Do you really want delete your account?"
                onClose={this.onCloseConfirmDialog}
                onConfirm={this.sendDeleteAccount}
              />
            ) : null}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
