import React, { Component } from "react";
import CookieHelper from "../../CookieHelper";
import identityService from "../../services/identity.service";
import ProfilePictureEditorDialog from "../general/dialog/profile-picture-editor-modal-dialog.component";
import WarningDialog from "../general/dialog/warning-modal-dialog.component";
import AccountProfileIcon from "../../icons/account-outline.svg";

export default class AccountSettingScreen extends Component {
  constructor(props) {
    super(props);

    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangeFullName = this.onChangeFullName.bind(this);
    this.onChangePreferredName = this.onChangePreferredName.bind(this);
    this.onChangeBio = this.onChangeBio.bind(this);
    this.onChangeJobTitle = this.onChangeJobTitle.bind(this);
    this.onChangeOfficeLocation = this.onChangeOfficeLocation.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeCompanyWebsite = this.onChangeCompanyWebsite.bind(this);
    this.onChangeTwitter = this.onChangeTwitter.bind(this);
    this.onChangeMastodon = this.onChangeMastodon.bind(this);
    this.onChangeGithub = this.onChangeGithub.bind(this);
    this.onChangeWebsite = this.onChangeWebsite.bind(this);
    this.sendChangedProfileInformation =
      this.sendChangedProfileInformation.bind(this);
    this.onCloseSuccessDialog = this.onCloseSuccessDialog.bind(this);
    this.onCloseWarningDialog = this.onCloseWarningDialog.bind(this);
    this.onCloseConfirmDialog = this.onCloseConfirmDialog.bind(this);
    this.showSuccessNotification = this.showSuccessNotification.bind(this);
    this.showWarningNotification = this.showWarningNotification.bind(this);
    this.getProfileInformation = this.getProfileInformation.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.onCancelProfilePictureEditor =
      this.onCancelProfilePictureEditor.bind(this);
    this.saveProfilePicture = this.saveProfilePicture.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeMobile = this.onChangeMobile.bind(this);

    this.state = {
      profilePhoto: {},
      profileInformation: {},
      profileAbout: {},
      profileSocialLinks: {},
      profileContact: {},
      isLoading: true,
      showSuccessDialog: false,
      showWarningDialog: false,
      showConfirmDialog: false,
      successMessage: "",
      errorMessage: "",
      selectedPicture: null,
    };
  }

  onChangeUserName(e) {
    this.setState((prevState) => {
      return {
        profileInformation: {
          ...prevState.profileInformation,
          userName: e.target.value,
        },
      };
    });
  }

  onChangeFullName(e) {
    this.setState((prevState) => {
      return {
        profileInformation: {
          ...prevState.profileInformation,
          fullName: e.target.value,
        },
      };
    });
  }

  onChangePreferredName(e) {
    this.setState((prevState) => {
      return {
        profileInformation: {
          ...prevState.profileInformation,
          preferredName: e.target.value,
        },
      };
    });
  }

  onChangeBio(e) {
    this.setState((prevState) => {
      return {
        profileAbout: {
          ...prevState.profileAbout,
          bio: e.target.value,
        },
      };
    });
  }

  onChangeJobTitle(e) {
    this.setState((prevState) => {
      return {
        profileAbout: {
          ...prevState.profileAbout,
          jobTitle: e.target.value,
        },
      };
    });
  }

  onChangeOfficeLocation(e) {
    this.setState((prevState) => {
      return {
        profileAbout: {
          ...prevState.profileAbout,
          officeLocation: e.target.value,
        },
      };
    });
  }

  onChangeCompany(e) {
    this.setState((prevState) => {
      return {
        profileAbout: {
          ...prevState.profileAbout,
          company: e.target.value,
        },
      };
    });
  }

  onChangeCompanyWebsite(e) {
    this.setState((prevState) => {
      return {
        profileAbout: {
          ...prevState.profileAbout,
          companyWebsite: e.target.value,
        },
      };
    });
  }

  onChangeTwitter(e) {
    this.setState((prevState) => {
      return {
        profileSocialLinks: {
          ...prevState.profileSocialLinks,
          twitter: e.target.value,
        },
      };
    });
  }

  onChangeMastodon(e) {
    this.setState((prevState) => {
      return {
        profileSocialLinks: {
          ...prevState.profileSocialLinks,
          mastodon: e.target.value,
        },
      };
    });
  }

  onChangeGithub(e) {
    this.setState((prevState) => {
      return {
        profileSocialLinks: {
          ...prevState.profileSocialLinks,
          github: e.target.value,
        },
      };
    });
  }

  onChangeWebsite(e) {
    this.setState((prevState) => {
      return {
        profileSocialLinks: {
          ...prevState.profileSocialLinks,
          website: e.target.value,
        },
      };
    });
  }

  onChangeMobile(e) {
    this.setState((prevState) => {
      return {
        profileContact: {
          ...prevState.profileContact,
          mobile: e.target.value,
        },
      };
    });
  }

  onChangePhone(e) {
    this.setState((prevState) => {
      return {
        profileContact: {
          ...prevState.profileContact,
          phone: e.target.value,
        },
      };
    });
  }

  componentDidMount() {
    this.getProfileInformation();
  }

  getProfileInformation() {
    identityService
      .getProfileInfo()
      .then((response) => {
        const data = response.data;
        this.setState({
          profileAbout: data.about,
          profilePhoto: data.photo,
          profileInformation: data.information,
          profileSocialLinks: data.socialLinks,
          profileContact: data.contact,
          isLoading: false,
        });
      })
      .catch((e) => {
        CookieHelper.remove();
        window.location.href = "/auth/login";
      });
  }

  onCloseSuccessDialog() {
    this.setState({
      successMessage: "",
      showSuccessDialog: false,
    });
  }

  onCloseWarningDialog() {
    this.setState({
      showWarningDialog: false,
      errorMessage: "",
    });
  }

  onCloseConfirmDialog() {
    this.setState({
      confirmMessage: "",
      showConfirmDialog: false,
    });
  }

  sendChangedProfileInformation() {
    this.setState({
      isLoading: true,
    });

    const profilePhoto = this.state.profilePhoto;
    const profileAbout = this.state.profileAbout;
    const profileInformation = this.state.profileInformation;
    const profileSocialLinks = this.state.profileSocialLinks;
    const profileContact = this.state.profileContact;

    identityService
      .updateProfileInfo(
        profilePhoto,
        profileInformation,
        profileAbout,
        profileSocialLinks,
        profileContact
      )
      .then((response) => {
        this.showSuccessNotification("Profile information were updated");
        this.getProfileInformation();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          showWarningDialog: true,
          errorMessage: error.response.data.message,
        });
      });
  }

  showSuccessNotification(message) {
    this.props.showNotification("success", message);
  }

  showWarningNotification(message) {
    this.props.showNotification("warning", message);
  }

  handleImageUpload(e) {
    const file = e.target.files[0];
    this.setState({ selectedPicture: URL.createObjectURL(file) });
  }

  saveProfilePicture(editedPicture) {
    console.log(editedPicture);
    this.setState(
      {
        profilePhoto: {
          profilePicture: editedPicture,
        },
        selectedPicture: null,
      },
      () => this.sendChangedProfileInformation()
    );
  }

  onCancelProfilePictureEditor() {
    this.setState({
      selectedPicture: null,
    });
  }

  render() {
    const selectedPicture = this.state.selectedPicture;
    const profilePhoto = this.state.profilePhoto;
    const profilePicture = profilePhoto.profilePicture;

    const profileInformation = this.state.profileInformation;
    const userName = profileInformation.userName;
    const fullName = profileInformation.fullName;
    const preferredName = profileInformation.preferredName;

    const profileAbout = this.state.profileAbout;
    const bio = profileAbout.bio;
    const jobTitle = profileAbout.jobTitle;
    const officeLocation = profileAbout.officeLocation;
    const company = profileAbout.company;
    const companyWebsite = profileAbout.companyWebsite;

    const profileSocialLinks = this.state.profileSocialLinks;
    const twitter = profileSocialLinks.twitter;
    const mastodon = profileSocialLinks.mastodon;
    const github = profileSocialLinks.github;
    const website = profileSocialLinks.website;

    const profileContact = this.state.profileContact;
    const phone = profileContact.phone;
    const mobile = profileContact.mobile;

    const mobileDesign = this.props.mobileDesign;
    return (
      <div className="account_container">
        {this.state.isLoading ? <div id="loader" /> : null}

        <h2 className="container-title">Profile</h2>
        <div className="spacer_div_20"></div>

        <div className="section">
          <h3 className="section-title">Profile picture</h3>

          <div className="spacer_div_20"></div>
          <p className="message">
            Upload a picture to make your profile stand out and let people
            recognize easily your contributions, blog posts, content, notes,
            comments, etc!
          </p>

          <div className="spacer_div_40"></div>

          <img
            src={profilePicture ? profilePicture : AccountProfileIcon}
            className="profile-picture-img"
            alt="Profile picture"
            title="Profile picture"
          />

          <div className="spacer_div_40"></div>

          <label htmlFor="file-upload" className="setting-button">
            Change picture
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={this.handleImageUpload}
            accept="image/*"
            title="Upload new profile picture"
            style={{ display: "none" }}
          />
        </div>

        <div className="section">
          <h2 className="section-title">Profile information</h2>
          <div className="spacer_div_20"></div>

          <div className="property-container">
            <label className="property_title" for="username">
              Username
            </label>
            <input
              id="username"
              type="text"
              className="setting-input-box"
              value={userName}
              onChange={this.onChangeUserName}
            />
          </div>

          {mobileDesign === false ? <br /> : null}

          <div className="spacer_div_20"></div>

          <div className="property-container">
            <label className="property_title" for="full-name">
              Full name
            </label>
            <input
              id="full-name"
              type="text"
              className="setting-input-box"
              value={fullName}
              onChange={this.onChangeFullName}
            />
          </div>

          {mobileDesign === false ? <br /> : null}
          <div className="spacer_div_20"></div>

          <div className="property-container">
            <label className="property_title" for="preferred-name">
              Preferred name
            </label>
            <input
              id="preffered-name"
              type="text"
              className="setting-input-box"
              value={preferredName}
              onChange={this.onChangePreferredName}
            />
          </div>

          <div className="spacer_div_40"></div>

          <button
            className="setting-button"
            onClick={this.sendChangedProfileInformation}
          >
            Save changes
          </button>
        </div>

        <div className="section">
          <h2 className="section-title">About</h2>
          <div className="spacer_div_20"></div>

          <div className="property-container-full-width">
            <label className="property_title" for="bio">
              Bio
            </label>
            <textarea
              id="bio"
              type="text"
              className="setting-input-box"
              value={bio}
              onChange={this.onChangeBio}
              rows={8}
              style={{ resize: "none" }}
            />
          </div>

          <div className="spacer_div_20"></div>
          <div className="property-container">
            <label className="property_title" for="job-title">
              Job title
            </label>
            <input
              id="job-title"
              type="text"
              className="setting-input-box"
              value={jobTitle}
              onChange={this.onChangeJobTitle}
            />
          </div>

          {mobileDesign ? <div className="spacer_div_20"></div> : null}

          <div className="property-container">
            <label className="property_title" for="office-location">
              Office location
            </label>
            <input
              id="office-location"
              type="text"
              className="setting-input-box"
              value={officeLocation}
              onChange={this.onChangeOfficeLocation}
            />
          </div>

          {mobileDesign === false ? <br /> : null}

          <div className="spacer_div_20"></div>
          <div className="property-container">
            <label className="property_title" for="company">
              Company
            </label>
            <input
              id="company"
              type="text"
              className="setting-input-box"
              value={company}
              onChange={this.onChangeCompany}
            />
          </div>

          {mobileDesign ? <div className="spacer_div_20"></div> : null}

          <div className="property-container">
            <label className="property_title" for="company-website">
              Company website
            </label>
            <input
              id="company-website"
              type="text"
              className="setting-input-box"
              value={companyWebsite}
              onChange={this.onChangeCompanyWebsite}
            />
          </div>

          <div className="spacer_div_40"></div>

          <button
            className="setting-button"
            onClick={this.sendChangedProfileInformation}
          >
            Save changes
          </button>
        </div>

        <div className="section">
          <h2 className="section-title">Profile social links</h2>
          <div className="spacer_div_20"></div>

          <div className="property-container">
            <label className="property_title" for="twitter">
              Twitter
            </label>
            <input
              id="twitter"
              type="text"
              className="setting-input-box"
              value={twitter}
              onChange={this.onChangeTwitter}
            />
          </div>

          {mobileDesign ? <div className="spacer_div_20"></div> : null}

          <div className="property-container">
            <label className="property_title" for="mastodon">
              Mastodon
            </label>
            <input
              id="mastodon"
              type="text"
              className="setting-input-box"
              value={mastodon}
              onChange={this.onChangeMastodon}
            />
          </div>

          <div className="spacer_div_20"></div>

          <div className="property-container">
            <label className="property_title" for="github">
              Github
            </label>
            <input
              id="github"
              type="text"
              className="setting-input-box"
              value={github}
              onChange={this.onChangeGithub}
            />
          </div>

          {mobileDesign ? <div className="spacer_div_20"></div> : null}

          <div className="property-container">
            <label className="property_title" for="website">
              Your website
            </label>
            <input
              id="website"
              type="text"
              className="setting-input-box"
              value={website}
              onChange={this.onChangeWebsite}
            />
          </div>

          <div className="spacer_div_40"></div>

          <button
            className="setting-button"
            onClick={this.sendChangedProfileInformation}
          >
            Save changes
          </button>
        </div>

        <div className="section">
          <h2 className="section-title">Contact information</h2>
          <div className="spacer_div_20"></div>

          <div className="property-container">
            <label className="property_title" for="mobile">
              Mobile
            </label>
            <input
              id="mobile"
              type="text"
              className="setting-input-box"
              value={mobile}
              onChange={this.onChangeMobile}
            />
          </div>
          {mobileDesign ? <div className="spacer_div_20"></div> : null}

          <div className="property-container">
            <label className="property_title" for="phone">
              Phone
            </label>
            <input
              id="phone"
              type="text"
              className="setting-input-box"
              value={phone}
              onChange={this.onChangePhone}
            />
          </div>

          <div className="spacer_div_40"></div>

          <button
            className="setting-button"
            onClick={this.sendChangedProfileInformation}
          >
            Save changes
          </button>
        </div>

        {this.state.showWarningDialog ? (
          <WarningDialog
            message={this.state.errorMessage}
            onClose={this.onCloseWarningDialog}
          />
        ) : null}

        {selectedPicture && (
          <ProfilePictureEditorDialog
            selectedPicture={selectedPicture}
            onCancel={this.onCancelProfilePictureEditor}
            onSave={this.saveProfilePicture}
          />
        )}
      </div>
    );
  }
}
