import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthLogin from "./components/auth/auth-login.component";
import AuthRegister from "./components/auth/auth-register.component";
import DeleteAccount from "./components/auth/delete-account.component";
import ResetPasswordAfterRequest from "./components/auth/reset-password-after-request.component";
import ResetPassword from "./components/auth/reset-password.component";
import AccountMainScreen from "./components/account/account-main-screen.component";
import ErrorNotFound from "./components/error/error-not-found.component";

class App extends Component {

  render() {
    return (
      <Routes>
        <Route exact path="/" element={<Navigate to="/auth/login" />} />
        <Route exact path="/auth" element={<Navigate to="/auth/login" />} />
        <Route exact path="/auth/login" element={<AuthLogin />} />
        <Route exact path="/auth/register" element={<AuthRegister />} />
        <Route exact path="/auth/reset-password" element={<ResetPassword />} />
        <Route
          exact
          path="/auth/delete-account/:pathVariable"
          element={<DeleteAccount />}
        />
        <Route
          exact
          path="/auth/reset-password/:pathVariable"
          element={<ResetPasswordAfterRequest />}
        />
        <Route exact path="/account" element={<AccountMainScreen />} />
        <Route
          path="*"
          element={<ErrorNotFound />}
        />
      </Routes>
    );
  }
}

export default App;
