import React, { Component } from "react";

import "../css/sidebar.css";

import LockIcon from "../../../../icons/lock-outline.svg";
import AppIcon from "../../../../icons/app-icon-full.png";
import SidebarMenuItem from "./sidebar-menu-item.component";
import ExpandIcon from "../icons/chevron-right.svg";
import CollapseIcon from "../icons/chevron-left.svg";
import AccountIcon from "../../../../icons/account-outline.svg";
import NotificationIcon from "../icons/bell-outline.svg";
import OtherAppsIcon from "../icons/dots-grid.svg";
import CloseOctagonOutline from "../icons/close-octagon-outline.svg";
import identitySidebarService from "../services/identity-sidebar.service";
import SidebarProfileMenu from "./sidebar-profile-menu.component";
import { Link } from "react-router-dom";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      activeItem: "SETTING_SCREEN",
      showProfileInfoMenu: false,
      profileInfo: {},
    };

    this.changeScreen = this.changeScreen.bind(this);
    this.getProfileInfo = this.getProfileInfo.bind(this);
  }

  changeScreen(screenType) {
    this.props.onSideBarMenuItemClick(screenType);

    this.setState({
      activeItem: screenType,
    });
  }

  componentDidMount() {
    this.getProfileInfo();
  }

  getProfileInfo() {
    identitySidebarService.getSidebarProfileInfo().then((response) => {
      this.setState({
        profileInfo: response.data,
      });
    });
  }

  render() {
    const collapsed = this.state.collapsed;
    const notCollapsed = !collapsed;
    const activeItem = this.state.activeItem;

    const profileInfo = this.state.profileInfo;
    const userName = profileInfo.name;
    const profilePicture = profileInfo.profilePhoto;

    const showProfileInfoMenu = this.state.showProfileInfoMenu;

    return (
      <aside
        className={
          "sidebar-container " +
          (collapsed ? "sidebar-container-collapsed" : "")
        }
      >
        <SidebarProfileMenu
          openMenu={showProfileInfoMenu}
          profileUserName={userName}
          profilePicture={profilePicture ? profilePicture : AccountIcon}
        />
        <Link to="/account" className="sidebar-app-info-container">
          <img
            alt="Identity"
            title="Identity"
            src={AppIcon}
            className="sidebar-app-icon"
          />
          {notCollapsed ? <p className="sidebar-app-title">Identity</p> : null}
        </Link>
        <div className="sidebar-div-spacer-40" />

        <div
          className={
            "sidebar-item-list" +
            (collapsed ? " sidebar-item-list-collapsed" : "")
          }
        >
          <SidebarMenuItem
            active={activeItem === "SETTING_SCREEN"}
            label={"Profile"}
            icon={AccountIcon}
            collapsed={collapsed}
            onMenuItemClick={() => {
              this.changeScreen("SETTING_SCREEN");
            }}
          />

          <SidebarMenuItem
            active={activeItem === "SECURITY_SCREEN"}
            label={"Security"}
            icon={LockIcon}
            collapsed={collapsed}
            onMenuItemClick={() => {
              this.changeScreen("SECURITY_SCREEN");
            }}
          />

          <SidebarMenuItem
            active={activeItem === "OTHER_SCREEN"}
            label={"Danger zone"}
            icon={CloseOctagonOutline}
            collapsed={collapsed}
            onMenuItemClick={() => {
              this.changeScreen("OTHER_SCREEN");
            }}
          />
        </div>
        {collapsed ? (
          <div className="sidebar-bottom-container">
            <div
              className="sidebar-bottom-icon-container"
              onClick={() => {
                this.setState(
                  {
                    showProfileInfoMenu: false,
                  },
                  () => {
                    this.setState({
                      showProfileInfoMenu: true,
                    });
                  }
                );
              }}
            >
              <img
                src={profilePicture ? profilePicture : AccountIcon}
                className="sidebar-profile-photo"
                alt={userName}
                title={userName}
              />
            </div>

            <div
              className="sidebar-bottom-icon-container"
              onClick={() => {
                this.setState({
                  showNotifications: true,
                });
              }}
            >
              <img
                src={NotificationIcon}
                className="sidebar-bottom-icon"
                alt="Notifications"
                title="Notifications"
              />
            </div>

            <div
              className="sidebar-bottom-icon-container"
              onClick={() => {
                this.setState({
                  showOtherApps: true,
                });
              }}
            >
              <img
                src={OtherAppsIcon}
                className="sidebar-bottom-icon"
                alt="Other apps"
                title="Other apps"
              />
            </div>

            <div
              className="sidebar-bottom-icon-container"
              onClick={() => {
                this.setState({
                  collapsed: false,
                });
              }}
            >
              <img
                src={ExpandIcon}
                title="Expand"
                alt="Expand"
                className="sidebar-bottom-expand-icon"
              />
            </div>
          </div>
        ) : (
          <div className="sidebar-bottom-container" style={{ display: "flex" }}>
            <div className="sidebar-bottom-icon-container">
              <img
                src={profilePicture ? profilePicture : AccountIcon}
                className="sidebar-profile-photo"
                alt={userName}
                title={userName}
                onClick={() => {
                  this.setState(
                    {
                      showProfileInfoMenu: false,
                    },
                    () => {
                      this.setState({
                        showProfileInfoMenu: true,
                      });
                    }
                  );
                }}
              />
            </div>

            <div
              className="sidebar-bottom-icon-container"
              onClick={() => {
                this.setState({
                  showNotifications: true,
                });
              }}
            >
              <img
                src={NotificationIcon}
                className="sidebar-bottom-icon"
                alt="Notifications"
                title="Notifications"
              />
            </div>

            <div
              className="sidebar-bottom-icon-container"
              onClick={() => {
                this.setState({
                  showOtherApps: true,
                });
              }}
            >
              <img
                src={OtherAppsIcon}
                className="sidebar-bottom-icon"
                alt="Other apps"
                title="Other apps"
              />
            </div>

            <div
              style={{ marginLeft: "auto" }}
              className="sidebar-bottom-icon-container"
            >
              <img
                className="sidebar-bottom-collapse-icon"
                src={CollapseIcon}
                title="Collapse"
                alt="Collapse"
                onClick={() => {
                  this.setState({
                    collapsed: true,
                  });
                }}
              />
            </div>
          </div>
        )}
      </aside>
    );
  }
}
