import Cookies from "js-cookie";

const cookieName = "x-session";

class CookieHelper {
  extendValidity() {
    const cookieValue = Cookies.get(cookieName);
    Cookies.remove(cookieName);
    Cookies.set(cookieName, cookieValue, {
      expires: 7,
      domain: "davidsimak.dev",
    });
  }

  remove() {
    Cookies.remove(cookieName, { domain: "davidsimak.dev" });
  }

  create(cookieValue) {
    this.remove();
    Cookies.set(cookieName, cookieValue, {
      expires: 14,
      domain: "davidsimak.dev",
    });
  }

  isInBrowser() {
    return this.get() !== "";
  }

  isNotInBrowser() {
    return this.get() === "";
  }

  get() {
    const cookieValue = Cookies.get(cookieName, { domain: "davidsimak.dev" });

    return cookieValue === undefined ? "" : cookieValue;
  }
}

export default new CookieHelper();
