import React, { Component } from "react";

import warningIcon from "../../../icons/dialog-warning-icon.svg"

export default class WarningDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };

    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
    this.setState({
      message: this.props.message,
    });
  }

  closeDialog() {
    this.props.onClose();
  }

  render() {
    const message = this.state.message;

    return (
      <div className="dialog_wrapper">
        <div className="dialog">
          <div className="dialog_title_section">
            <img title="Warning" alt="Warning" src={warningIcon} />
            Warning
          </div>
          <p>{message}</p>
          <div className="div_dialog_buttons_container">
            <div className="div_dialog_buttons">
              <button
                className="dialog_button dialog_close"
                onClick={this.closeDialog}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
