import React, { Component } from "react";

import confirmIcon from "../../../icons/dialog-confirm-icon.svg";

export default class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };

    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  componentDidMount() {
    this.setState({
      message: this.props.message,
    });
  }

  confirm() {
    this.props.onConfirm();
  }

  close() {
    this.props.onClose();
  }

  render() {
    const message = this.state.message;

    return (
      <div className="dialog_wrapper">
        <div className="dialog">
          <div className="dialog_title_section">
            <img title="Confirm" alt="Confirm" src={confirmIcon} />
            Confirm action
          </div>
          <p>{message}</p>
          <div className="div_dialog_buttons_container">
            <div className="div_dialog_buttons">
              <button
                className="dialog_button dialog_cancel"
                onClick={this.close}
              >
                Cancel
              </button>
              <button
                className="dialog_button dialog_confirm"
                onClick={this.confirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
