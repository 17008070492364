import React, { Component } from "react";

export default class ErrorNotFound extends Component {

  componentDidMount() {
    document.title = "Redirecting | Identity";
    window.location="https://www.davidsimak.dev/error/not-found"
  }

  render() {

    return (
      <div className="container mt-3">
      </div>
    );
  }
}
