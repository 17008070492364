import axios from "axios";
import CookieHelper from "./CookieHelper";

const cookieValue = CookieHelper.get();

const prodBaseUrl = "https://api.davidsimak.dev/identity-api";
// const testBaseUrl = "http://localhost:8080"

export default axios.create({
  baseURL: prodBaseUrl,
  withCredentials: false,
  headers: {
    "x-session": cookieValue,
  },
});
