import React, { Component } from "react";
import CookieHelper from "../../CookieHelper";
import methods from "../../methods";
import reportVisitService from "../../reporting-visit/report-visit.service";
import identityService from "../../services/identity.service";
import WarningDialog from "../general/dialog/warning-modal-dialog.component";
import Footer from "../general/footer/component/footer.component";
import AppIcon from "../../icons/app-icon-full.png";
import { Link } from "react-router-dom";

export default class AuthRegister extends Component {
  constructor(props) {
    super(props);
    this.register = this.register.bind(this);
    this.checkTermsOfServices = this.checkTermsOfServices.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.onCloseWarningDialog = this.onCloseWarningDialog.bind(this);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      checkedTermsOfService: false,
      errorMessage: "",
      isLoading: false,
      showWarningDialog: false,
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  register() {
    if (
      this.state.email.length === 0 ||
      !methods.validateEmail(this.state.email)
    ) {
      this.setState({
        showWarningDialog: true,
        errorMessage: "To register to app, you must enter valid email!",
      });
      return;
    }

    if (!this.state.checkedTermsOfService) {
      this.setState({
        showWarningDialog: true,
        errorMessage:
          "To register to app, you must agree with Terms of Service and Privacy Policy!",
      });
      return;
    }

    if (this.state.password.length === 0) {
      this.setState({
        showWarningDialog: true,
        errorMessage: "To register to app, you must enter password!",
      });
      return;
    }

    if (this.state.confirmPassword.length === 0) {
      this.setState({
        showWarningDialog: true,
        errorMessage: "To register to app, you must enter confirm password!",
      });
    }

    if (this.state.confirmPassword !== this.state.password) {
      this.setState({
        showWarningDialog: true,
        errorMessage: "Passwords must match!",
      });
      return;
    }

    this.setState({
      isLoading: true,
    });

    identityService
      .register(
        this.state.email,
        this.state.password,
        this.state.checkedTermsOfService
      )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          CookieHelper.create(response.data.session);
          let params = window.location.search;
          if (params.includes("?appName")) {
            window.location.href =
              "https://" + params.replace("?appName=", "") + ".davidsimak.dev/";
          } else {
            window.location.href = "/account";
          }
        } else {
          this.setState({
            isLoading: false,
            showWarningDialog: true,
            errorMessage: response.data.message,
          });
        }
      })
      .catch((e) => {
        methods.processError(e);
      });
  }

  onChangeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  checkTermsOfServices = () => {
    this.setState({
      checkedTermsOfService: !this.state.checkedTermsOfService,
    });
  };

  componentDidMount() {
    document.title = "Identity | Create new account";

    reportVisitService.report(window.location.pathname, document.title);

    if (CookieHelper.isInBrowser()) {
      window.location.href = "/account/setting";
    }
  }

  onCloseWarningDialog() {
    this.setState({
      errorMessage: "",
      showWarningDialog: false,
    });
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? <div id="loader" /> : null}

        <div className="auth-app-header">
          <img className="auth-app-icon" src={AppIcon} />
          <h1 className="auth-app-title">Identity</h1>
        </div>

        <div id="page_content_wrapper" className="page_content_wrapper">
          <div className="auth_form">
            <h2 className="title_label">Create new account</h2>
            <p className="title_des">Please fill in form to continue</p>
            <div className="spacer_div_40"></div>
            <label className="property_title">Email</label>
            <input
              className="input_box"
              type="email"
              value={this.state.email}
              onChange={this.onChangeEmail}
            />
            <div className="spacer_div_20"></div>
            <label className="property_title">Password</label>
            <input
              type="password"
              id="password"
              className="input_box"
              value={this.state.password}
              onChange={this.onChangePassword}
            />
            <div className="spacer_div_20"></div>
            <label className="property_title">Confirm password</label>
            <input
              type="password"
              id="confirm_password"
              className="input_box"
              value={this.state.confirmPassword}
              onChange={this.onChangeConfirmPassword}
              placeholder="Confirm Password"
            />
            <div className="spacer_div_20"></div>
            <button
              className={
                this.state.checkedTermsOfService
                  ? "btn_checked"
                  : "btn_unchecked"
              }
              onClick={this.checkTermsOfServices}
            />{" "}
            <label className="agree_to_pp_tos">
              Agree to{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.davidsimak.dev/legal/privacy-policy/"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.davidsimak.dev/legal/terms-of-service/"
              >
                Terms of Service
              </a>
            </label>
            <div className="spacer_div_40"></div>
            <button className="auth_button" onClick={this.register}>
              Register
            </button>
            <div className="spacer_div_80"></div>
            <label className="register_label">
              Already have an account? <Link to="/auth/login">Login now</Link>
            </label>
          </div>
        </div>

        {this.state.showWarningDialog ? (
          <WarningDialog
            message={this.state.errorMessage}
            onClose={this.onCloseWarningDialog}
          />
        ) : null}

        <Footer />
      </div>
    );
  }
}
