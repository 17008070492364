import React, { Component } from "react";

import twitterIcon from "../icons/twitter-gray.svg";
import emailIcon from "../icons/email-gray.svg";
import linkedInIcon from "../icons/linkedin-gray.svg";
import "../css/footer.css"

export default class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      footerClassName: "footer footer_fixed",
      oldHeightOfGrid: 0,
      oldPath: "",
    };

    this.updateFooterPosition = this.updateFooterPosition.bind(this);
  }

  componentDidMount() {
    this.updateFooterPosition();
    window.addEventListener("resize", this.updateFooterPosition);
  }

  updateFooterPosition() {
    if (
      this.state.oldHeightOfGrid !==
      document.getElementById("page_content_wrapper").clientHeight
    ) {
      const heightOfGrid = document.getElementById(
        "page_content_wrapper"
      ).clientHeight;
      const heightOfWindow = window.innerHeight;
      var newFooterClassName = "footer footer_";
      if (heightOfWindow < heightOfGrid + 100) {
        newFooterClassName += "bottom";
      } else {
        newFooterClassName += "fixed";
      }

      this.setState({
        oldHeightOfGrid: heightOfGrid,
        footerClassName: newFooterClassName,
      });
    }
  }

  render() {
    return (
      <footer className={this.state.footerClassName}>
        <a
          className="footer_text"
          href="https://www.davidsimak.dev"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>David</b>Šimák | Self-taught Java/Kotlin developer
        </a>
        <div className="footer_links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto://david@davidsimak.dev"
          >
            <img src={emailIcon} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/david-simak/"
          >
            <img src={linkedInIcon} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/davidsimakdev"
          >
            <img src={twitterIcon} />
          </a>
        </div>
      </footer>
    );
  }
}
