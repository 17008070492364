import React, { Component } from "react";

export default class SidebarMenuItem extends Component {
  constructor(props) {
    super(props);

    this.onMenuItemClick = this.onMenuItemClick.bind(this);
  }

  onMenuItemClick() {
    this.props.onMenuItemClick();
  }

  render() {
    const icon = this.props.icon;
    const label = this.props.label;
    const active = this.props.active;
    const collapsed = this.props.collapsed;
    const notCollapsed = !collapsed;

    const customImgClass = this.props.customImgClass;

    return (
      <div
        className={
          "sidebar-menu-item " +
          (collapsed ? "sidebar-menu-item-collapsed " : "") +
          (active ? "sidebar-menu-item-active" : "")
        }
        onClick={this.onMenuItemClick}
      >
        <img
          alt={label}
          title={label}
          className={customImgClass ? customImgClass : "sidebar-menu-item-icon"}
          src={icon}
        />
        {notCollapsed ? (
          <p className="sidebar-menu-item-label">{label}</p>
        ) : null}
      </div>
    );
  }
}
