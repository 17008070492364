import React, { Component } from "react";
import reportVisitService from "../../reporting-visit/report-visit.service";
import identityService from "../../services/identity.service";
import WarningDialog from "../general/dialog/warning-modal-dialog.component";
import Footer from "../general/footer/component/footer.component";
import AppIcon from "../../icons/app-icon-full.png";

export default class ResetPasswordAfterRequest extends Component {
  constructor(props) {
    super(props);
    this.changePassword = this.changePassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.onCloseWarningDialog = this.onCloseWarningDialog.bind(this);

    this.state = {
      pathVariable: "",
      password: "",
      confirmPassword: "",
      isLoading: false,
      showWarningDialog: false,
    };
  }

  changePassword() {
    if (this.state.password.length > 0) {
      if (this.state.confirmPassword.length > 0) {
        if (this.state.confirmPassword === this.state.password) {
          this.setState({
            isLoading: true,
          });
          identityService
            .changePasswordAfterReset(
              this.state.pathVariable,
              this.state.password
            )
            .then((response) => {
              this.setState({
                isLoading: false,
              });
              window.location.href = "/auth/login";
            })
            .catch((error) => {
              this.setState({
                isLoading: false,
                showWarningDialog: true,
                errorMessage: error.response.data.message,
              });
            });
        } else {
          this.setState({
            showWarningDialog: true,
            errorMessage: "You must enter same passwords!",
          });
        }
      } else {
        this.setState({
          showWarningDialog: true,
          errorMessage: "You must enter confirm password!",
        });
      }
    } else {
      this.setState({
        showWarningDialog: true,
        errorMessage: "You must enter password!",
      });
    }
  }

  onChangeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  componentDidMount() {
    document.title = "Identity | Change your password";

    const path = window.location.pathname;
    reportVisitService.report(path, document.title);

    const pathVariableSplit = path.split("/");
    const pathVariable = pathVariableSplit[pathVariableSplit.length - 1];

    this.setState({
      pathVariable: pathVariable,
    });
  }

  onCloseWarningDialog() {
    this.setState({
      errorMessage: "",
      showWarningDialog: false,
    });
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? <div id="loader" /> : null}

        <div className="auth-app-header">
          <img className="auth-app-icon" src={AppIcon} />
          <h1 className="auth-app-title">Identity</h1>
        </div>

        <div id="page_content_wrapper" className="page_content_wrapper">
          <div className="auth_form">
            <h2 className="title_label">Change your password</h2>
            <p className="title_des">Please fill your new password</p>

            <div className="spacer_div_40"></div>

            <label className="property_title">Password</label>
            <input
              type="password"
              id="password"
              className="input_box"
              value={this.state.password}
              onChange={this.onChangePassword}
            />
            <div className="spacer_div_20"></div>

            <label className="property_title">Confirm new password</label>

            <input
              type="password"
              id="confirm_password"
              className="input_box"
              value={this.state.confirmPassword}
              onChange={this.onChangeConfirmPassword}
            />
            <div className="spacer_div_40"></div>
            <button className="auth_button" onClick={this.changePassword}>
              Change password
            </button>
            {this.state.showWarningDialog ? (
              <WarningDialog
                message={this.state.errorMessage}
                onClose={this.onCloseWarningDialog}
              />
            ) : null}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
