import React, { Component } from "react";
import CookieHelper from "../../CookieHelper";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import reportVisitService from "../../reporting-visit/report-visit.service";
import AccountSettingScreen from "./account-setting-screen.component";
import AccountSecurityScreen from "./account-security-screen.component";
import AccountOtherScreen from "./account-other-screen.component";
import Sidebar from "../general/sidebar/components/sidebar.component";
import SidebarBurgerMenu from "../general/sidebar/components/sidebar-burger-menu.component";
import HeaderMobile from "../general/sidebar/components/header-mobile.component";

export default class AccountMainScreen extends Component {
  constructor(props) {
    super(props);

    this.changeScreen = this.changeScreen.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.updateWidthOfMainContainer =
      this.updateWidthOfMainContainer.bind(this);

    this.state = {
      screenType: "SETTING_SCREEN",
      oldWidthOfMainContainer: 0,
      mobileDesign: false
    };
  }

  changeScreen(screenType) {
    this.setState({
      screenType: screenType,
      oldWidthOfMainContainer: 0,
    });
  }

  showNotification(type, message) {
    switch (type) {
      case "info":
        NotificationManager.info(message);
        break;
      case "error":
        NotificationManager.error(message);
        break;
      case "success":
        NotificationManager.success(message);
        break;
      case "warning":
        NotificationManager.warning(message);
        break;
      default:
        break;
    }
  }

  componentDidUpdate() {
    this.updateWidthOfMainContainer();
  }

  componentDidMount() {
    document.title = "Identity | Account management";

    if (CookieHelper.isNotInBrowser()) {
      window.location.href = "/auth/login";
    }
    const path = window.location.pathname;
    reportVisitService.report(path, document.title);
    window.addEventListener("resize", this.updateWidthOfMainContainer);
    this.updateWidthOfMainContainer();
  }

  updateWidthOfMainContainer() {
    const mainContainer = document.getElementById("main-container");
    const widthOfMainContainer = mainContainer.clientWidth;

    var mobileDesign = false;
    if (this.state.oldWidthOfMainContainer != widthOfMainContainer) {
      if (widthOfMainContainer <= 900) {
        mainContainer
          .querySelectorAll(".property-container")
          .forEach((child) => {
            child.classList.add("full-width");
          });
        mainContainer
          .querySelectorAll(".property-container-section")
          .forEach((child) => {
            child.classList.add("section-full-width");
          });

          mobileDesign = true;
      } else {
        mainContainer
          .querySelectorAll(".property-container")
          .forEach((child) => {
            child.classList.remove("full-width");
          });
        mainContainer
          .querySelectorAll(".property-container-section")
          .forEach((child) => {
            child.classList.remove("section-full-width");
          });
      }

      this.setState({
        oldWidthOfMainContainer: widthOfMainContainer,
        mobileDesign: mobileDesign
      });
    }
  }

  render() {
    const screenType = this.state.screenType;
    const mobileDesign = this.state.mobileDesign;

    return (
      <div style={{ display: "flex", height: "100%" }}>
        <HeaderMobile />
        <NotificationContainer />
        <Sidebar onSideBarMenuItemClick={this.changeScreen} />
        <SidebarBurgerMenu onSideBarMenuItemClick={this.changeScreen} />
        <main id="main-container" className="main-container">
          {screenType === "SETTING_SCREEN" ? (
            <AccountSettingScreen
              showNotification={this.showNotification}
              mobileDesign={mobileDesign}
            />
          ) : null}

          {screenType === "SECURITY_SCREEN" ? (
            <AccountSecurityScreen
              showNotification={this.showNotification}
              mobileDesign={mobileDesign}
            />
          ) : null}

          {screenType === "OTHER_SCREEN" ? (
            <AccountOtherScreen
              showNotification={this.showNotification}
              mobileDesign={mobileDesign}
            />
          ) : null}
        </main>
      </div>
    );
  }
}
