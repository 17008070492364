class DateHelper {
  getHumanDate(date) {

    if (!date) {
      return ""
    }
    return (
      this.getShortMonthDisplayName(date) +
      " " +
      date.getUTCDate() +
      ", " +
      date.getUTCFullYear().toString() +
      " " +
      this.addZerosToNumber(date.getUTCHours()) +
      ":" +
      this.addZerosToNumber(date.getUTCMinutes()) +
      (date.getUTCSeconds() !== 0
        ? ":" + this.addZerosToNumber(date.getUTCSeconds())
        : "")
    );
  }

  addZerosToNumber(number) {
    var num = number.toString();
    if (num.length === 2) {
      return num;
    }

    return "0" + num;
  }

  getShortMonthDisplayName(date) {
    return date.toLocaleString("en-US", { month: "short" });
  }
}

export default new DateHelper();
