import React, { Component } from "react";
import ConfirmDialog from "../general/dialog/confirm-modal-dialog.component";
import SuccessDialog from "../general/dialog/success-modal-dialog.component";
import WarningDialog from "../general/dialog/warning-modal-dialog.component";
import methods from "../../methods";
import reportVisitService from "../../reporting-visit/report-visit.service";
import identityService from "../../services/identity.service";
import Footer from "../general/footer/component/footer.component";
import AppIcon from "../../icons/app-icon-full.png";


export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.sendResetPassword = this.sendResetPassword.bind(this);
    this.onCloseWarningDialog = this.onCloseWarningDialog.bind(this);
    this.confirmResetPassword = this.confirmResetPassword.bind(this);
    this.onCloseSuccessDialog = this.onCloseSuccessDialog.bind(this);
    this.onCloseConfirmDialog = this.onCloseConfirmDialog.bind(this);

    this.state = {
      email: "",
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      showWarningDialog: false,
      showConfirmDialog: false,
      showSuccessDialog: false,
    };
  }

  componentDidMount() {
    document.title = "Identity | Reset your password";
    reportVisitService.report(window.location.pathname, document.title);
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  confirmResetPassword() {
    if (this.state.email.length > 0) {
      if (methods.validateEmail(this.state.email)) {
        this.setState({
          showConfirmDialog: true,
        });
      } else {
        this.setState({
          showWarningDialog: true,
          successMessage: "",
          errorMessage: "Your email address is not valid!",
        });
      }
    } else {
      this.setState({
        showWarningDialog: true,
        successMessage: "",
        errorMessage: "You must fill your e-mail address!",
      });
    }
  }

  sendResetPassword() {
    this.setState({
      isLoading: true,
      showConfirmDialog: false,
      showSuccessDialog: true,
    });

    identityService
      .resetPassword(this.state.email)
      .then((response) => {
        this.setState({
          showSuccessDialog:true,
          successMessage: response.data.message,
          errorMassage: "",
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          successMessage: "",
          showWarningDialog: true,
          isLoading: false,
          errorMessage: e.response.data.message,
        });
      });
  }

  onCloseWarningDialog() {
    this.setState({
      errorMessage: "",
      showWarningDialog: false,
    });
  }

  onCloseSuccessDialog() {
    this.setState({
      successMessage: "",
      showSuccessDialog: false,
    });
  }

  onCloseConfirmDialog() {
    this.setState({
      showConfirmDialog: false,
    });
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? <div id="loader" /> : null}

        <div className="auth-app-header">
          <img className="auth-app-icon" src={AppIcon} />
          <h1 className="auth-app-title">Identity</h1>
        </div>

        <div id="page_content_wrapper" className="page_content_wrapper">
          <div className="auth_form">
            <h2 className="title_label">Reset your password</h2>
            <p className="title_des">
              Please fill your e-mail of account for reset password
            </p>
            <div className="spacer_div_40"></div>
            <label className="property_title">Email</label>

            <input
              type="email"
              className="input_box"
              value={this.state.email}
              onChange={this.onChangeEmail}
            />
            <div className="spacer_div_40"></div>

            <button className="auth_button" onClick={this.confirmResetPassword}>
              Reset password
            </button>

            {this.state.showWarningDialog ? (
              <WarningDialog
                message={this.state.errorMessage}
                onClose={this.onCloseWarningDialog}
              />
            ) : null}

            {this.state.showSuccessDialog ? (
              <SuccessDialog
                message={this.state.successMessage}
                onClose={this.onCloseSuccessDialog}
              />
            ) : null}

            {this.state.showConfirmDialog ? (
              <ConfirmDialog
                message="Do you really want reset password? All your active sessions will be invalidated and you will be forced logout from all of your devices!"
                onClose={this.onCloseConfirmDialog}
                onConfirm={this.sendResetPassword}
              />
            ) : null}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
