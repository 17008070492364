import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";

import "../css/sidebar.css";

import LockIcon from "../../../../icons/lock-outline.svg";
import AppIcon from "../../../../icons/app-icon-full.png";
import SidebarMenuItem from "./sidebar-menu-item.component";
import SidebarProfileMenu from "./sidebar-profile-menu.component";
import AccountIcon from "../../../../icons/account-outline.svg";
import CookieHelper from "../../../../CookieHelper";
import NotificationIcon from "../icons/bell-outline.svg";
import OtherAppsIcon from "../icons/dots-grid.svg";
import CloseOctagonOutline from "../icons/close-octagon-outline.svg";
import identitySidebarService from "../services/identity-sidebar.service";
import CollapseIcon from "../icons/chevron-left.svg";


export default class SidebarBurgerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "SETTING_SCREEN",
      showProfileInfoMenu: false,
      isBurgerMenuOpened: false,
      profileInfo: {},
    };

    this.changeScreen = this.changeScreen.bind(this);
    this.getProfileInfo = this.getProfileInfo.bind(this);
  }

  changeScreen(screenType) {
    this.props.onSideBarMenuItemClick(screenType);

    this.setState({
      activeItem: screenType,
      isBurgerMenuOpened: true,
    }, () => {
      this.setState({
        isBurgerMenuOpened: false
      })
    });
  }

  componentDidMount() {
    this.getProfileInfo();
  }

  getProfileInfo() {
    identitySidebarService.getSidebarProfileInfo().then((response) => {
      this.setState({
        profileInfo: response.data,
      });
    });
  }

  render() {
    const activeItem = this.state.activeItem;

    const profileInfo = this.state.profileInfo;
    const userName = profileInfo.name;
    const profilePicture = profileInfo.profilePhoto;

    const showProfileInfoMenu = this.state.showProfileInfoMenu;

    const isBurgerMenuOpened = this.state.isBurgerMenuOpened;

    return (
      <Menu
        isOpen={isBurgerMenuOpened}
        menuClassName="sidebar-burger-menu"
        burgerBarClassName="sidebar-burger-menu-button"
        width={"100%"}
        onClose={() => {
          this.setState({
            isBurgerMenuOpened: false,
            showProfileInfoMenu: false,
          });
        }}
      >
        <SidebarProfileMenu
          openMenu={showProfileInfoMenu}
          profileUserName={userName}
          profilePicture={profilePicture ? profilePicture : AccountIcon}
          noOverlay={true}
          customWidth={"100%"}
        />
        <div className="sidebar-app-info-container">
          <img
            alt="Identity"
            title="Identity"
            src={AppIcon}
            className="sidebar-app-icon"
          />
          <p className="sidebar-app-title">Identity</p>
        </div>
        <div className="sidebar-div-spacer-40" />

        <div className="sidebar-item-list">
          <SidebarMenuItem
            active={activeItem === "SETTING_SCREEN"}
            label={"Profile"}
            icon={AccountIcon}
            collapsed={false}
            onMenuItemClick={() => {
              this.changeScreen("SETTING_SCREEN");
            }}
          />

          <SidebarMenuItem
            active={activeItem === "SECURITY_SCREEN"}
            label={"Security"}
            icon={LockIcon}
            collapsed={false}
            onMenuItemClick={() => {
              this.changeScreen("SECURITY_SCREEN");
            }}
          />

          <SidebarMenuItem
            active={activeItem === "OTHER_SCREEN"}
            label={"Danger zone"}
            icon={CloseOctagonOutline}
            collapsed={false}
            onMenuItemClick={() => {
              this.changeScreen("OTHER_SCREEN");
            }}
          />
        </div>
        <div className="sidebar-bottom-container" style={{ display: "flex" }}>
          <div className="sidebar-bottom-icon-container">
            <img
              src={profilePicture ? profilePicture : AccountIcon}
              className="sidebar-profile-photo"
              alt={userName}
              title={userName}
              onClick={() => {
                this.setState(
                  {
                    showProfileInfoMenu: false,
                  },
                  () => {
                    this.setState({
                      showProfileInfoMenu: true,
                    });
                  }
                );
              }}
            />
          </div>

          <div
            className="sidebar-bottom-icon-container"
            onClick={() => {
              this.setState({
                showNotifications: true,
              });
            }}
          >
            <img
              src={NotificationIcon}
              className="sidebar-bottom-icon"
              alt="Notifications"
              title="Notifications"
            />
          </div>

          <div
            className="sidebar-bottom-icon-container"
            onClick={() => {
              this.setState({
                showOtherApps: true,
              });
            }}
          >
            <img
              src={OtherAppsIcon}
              className="sidebar-bottom-icon"
              alt="Other apps"
              title="Other apps"
            />
          </div>
          <div
            style={{ marginLeft: "auto" }}
            className="sidebar-bottom-icon-container"
          >
            <img
              className="sidebar-bottom-collapse-icon"
              src={CollapseIcon}
              title="Collapse"
              alt="Collapse"
              onClick={() => {
                this.setState(
                  {
                    isBurgerMenuOpened: true,
                  },
                  () => {
                    this.setState({
                      isBurgerMenuOpened: false,
                    });
                  }
                );
              }}
            />
          </div>
        </div>
      </Menu>
    );
  }
}
