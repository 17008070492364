import http from "../http-common";

class IdentityService {
  resetPassword(email) {
    const data = {
      email: email,
    };

    return http.post("/auth/reset-password", data);
  }

  changePasswordAfterReset(pathVariable, newPassword) {
    const data = {
      pathVariable: pathVariable,
      newPassword: newPassword,
    };
    return http.post(`/auth/change-password-after-reset`, data);
  }

  login(identifier, password) {
    const data = {
      identifier: identifier,
      password: password,
    };
    return http.post(`/auth/login`, data);
  }

  register(email, password, agree) {
    const data = {
      email: email,
      password: password,
      agreeToPrivacyPolicyAndTermsOfService: agree,
    };
    return http.post(`/auth/register`, data);
  }

  deleteAccount(pathVariable) {
    return http.delete(`/auth/delete-account/${pathVariable}`);
  }

  changePassword(oldPassword, newPassword) {
    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    return http.put("/account/change-password", data);
  }

  delete() {
    return http.delete("/account");
  }

  changeEmail(email) {
    const data = {
      email: email,
    };

    return http.put("/account/change-email", data);
  }

  updateProfileInfo(profilePhoto, profileInformation, profileAbout, profileSocialLinks, profileContact) {
    const data = {
      photo: profilePhoto,
      information: profileInformation,
      about: profileAbout,
      socialLinks: profileSocialLinks,
      contact: profileContact
    };

    return http.put("/account", data);
  }

  invalidateSessions() {
    return http.put("/account/invalidate-sessions");
  }

  getProfileInfo() {
      return http.get("/account/profile-info")
  }

  getSecurityInfo() {
      return http.get("/account/security")
  }
}

export default new IdentityService();
