import React, { Component } from "react";
import AvatarEditor from "react-avatar-editor";

import confirmIcon from "../../../icons/dialog-confirm-icon.svg";
import magnifyPlusIcon from "../../../icons/magnify-plus-outline.svg";
import magnifyMinusIcon from "../../../icons/magnify-minus-outline.svg";

export default class ProfilePictureEditorDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPicture: null,
      editedPicture: null,
      pictureScale: 1,
    };

    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.addScale = this.addScale.bind(this);
    this.minusScale = this.minusScale.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectedPicture: this.props.selectedPicture,
    });
  }

  addScale() {
    this.setState({
      pictureScale: this.state.pictureScale + 0.1,
    });
  }

  minusScale() {
    if (this.state.pictureScale > 1) {
      this.setState({
        pictureScale: this.state.pictureScale - 0.1,
      });
    }
  }

  save() {
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = this.editor.getImage();

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const dataURL = canvasScaled.toDataURL();
      console.log(dataURL);
      this.props.onSave(dataURL);
    }
  }

  cancel() {
    this.props.onCancel();
  }

  setEditorRef = (editor) => (this.editor = editor);

  render() {
    const selectedPicture = this.state.selectedPicture;
    const pictureScale = this.state.pictureScale;

    return (
      <div className="dialog_wrapper">
        <div className="dialog" style={{ width: "450px" }}>
          <div className="dialog_title_section">
            <img title="Confirm" alt="Confirm" src={confirmIcon} />
            Profile picture editor
          </div>
          <AvatarEditor
            image={selectedPicture}
            className="profile-picture-editor"
            width={250}
            height={250}
            border={50}
            color={[36, 37, 38, 0.5]} // RGBA
            scale={pictureScale}
            borderRadius={50}
            style={{ margin: "20px" }}
            ref={this.setEditorRef}
          />
          <div style={{marginTop: "10px"}}></div>
          <div className="div_dialog_buttons_container">
            <div className="div_dialog_buttons">
              <img
                src={magnifyMinusIcon}
                onClick={this.minusScale}
                className="magnify-button"
              />
              <img
                src={magnifyPlusIcon}
                onClick={this.addScale}
                className="magnify-button"
              />
            </div>
          </div>
          <div className="sidebar-div-spacer-20" />

          <div className="div_dialog_buttons_container">
            <div className="div_dialog_buttons">
              <button
                className="dialog_button dialog_cancel"
                onClick={this.cancel}
              >
                Cancel
              </button>
              <button
                className="dialog_button dialog_confirm"
                onClick={this.save}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
