import React, { Component } from "react";
import CookieHelper from "../../CookieHelper";
import dateHelper from "../../date-helper";
import identityService from "../../services/identity.service";
import ConfirmDialog from "../general/dialog/confirm-modal-dialog.component";
import SuccessDialog from "../general/dialog/success-modal-dialog.component";
import WarningDialog from "../general/dialog/warning-modal-dialog.component";
import methods from "../../methods";

export default class AccountSecurityScreen extends Component {
  constructor(props) {
    super(props);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
    this.onChangeConfirmNewPassword =
      this.onChangeConfirmNewPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.getPasswordInfo = this.getPasswordInfo.bind(this);
    this.onCloseSuccessDialog = this.onCloseSuccessDialog.bind(this);
    this.onCloseConfirmDialog = this.onCloseConfirmDialog.bind(this);
    this.onCloseWarningDialog = this.onCloseWarningDialog.bind(this);
    this.sendChangePassword = this.sendChangePassword.bind(this);
    this.showWarningNotification = this.showWarningNotification.bind(this);
    this.sendInvalidateAllActiveSessions =
      this.sendInvalidateAllActiveSessions.bind(this);
    this.invalidateAllActiveSessions =
      this.invalidateAllActiveSessions.bind(this);
    this.sendChangedEmail = this.sendChangedEmail.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);

    this.state = {
      securityInfo: {},
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      isLoading: true,
      successMessage: "",
      errorMessage: "",

      showConfirmDialog: false,
      showSuccessDialog: false,
      showWarningDialog: false,
      confirmAction: "",
    };
  }

  componentDidMount() {
    this.getPasswordInfo();
  }

  onChangePassword(e) {
    this.setState({
      oldPassword: e.target.value,
    });
  }

  onChangeNewPassword(e) {
    this.setState({
      newPassword: e.target.value,
    });
  }

  onChangeConfirmNewPassword(e) {
    this.setState({
      confirmNewPassword: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState((prevState) => {
      return {
        accountInformation: {
          ...prevState.accountInformation,
          email: e.target.value,
        },
      };
    });
  }

  onCloseSuccessDialog() {
    this.setState({
      successMessage: "",
      showSuccessDialog: false,
    });
    CookieHelper.remove();
    window.location.href = "/auth/login";
  }

  onCloseWarningDialog() {
    this.setState({
      showWarningDialog: false,
      errorMessage: "",
    });
  }

  onCloseConfirmDialog() {
    this.setState({
      confirmMessage: "",
      showConfirmDialog: false,
    });
  }

  getPasswordInfo() {
    identityService
      .getSecurityInfo()
      .then((response) => {
        this.setState({
          securityInfo: response.data,
          isLoading: false,
        });
      })
      .catch((e) => {
        CookieHelper.remove();
        window.location.href = "/auth/login";
      });
  }

  sendChangePassword() {
    this.setState({
      isLoading: true,
      showConfirmDialog: false,
    });
    identityService
      .changePassword(this.state.oldPassword, this.state.newPassword)
      .then((response) => {
        this.setState({
          isLoading: false,
          successMessage: "Your password was successfully changed!",
          showSuccessDialog: true,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMessage: error.response.data.message,
          showWarningDialog: true,
        });
      });
  }

  changeEmail() {
    const email = this.state.accountInformation.email;

    if (email === null || email.length === 0 || !methods.validateEmail(email)) {
      this.showWarningNotification("You must enter valid email!");
      return;
    }

    this.setState({
      showConfirmDialog: true,
      confirmAction: "CHANGE_EMAIL",
      confirmMessage: "Do you really want to change your email address!",
    });
  }

  changePassword() {
    if (this.state.oldPassword.length === 0) {
      this.showWarningNotification("You must enter your current password!");
      return;
    }

    if (this.state.newPassword.length === 0) {
      this.showWarningNotification("You must enter your new password!");
      return;
    }
    if (this.state.confirmNewPassword.length === 0) {
      this.showWarningNotification("You must confirm your new password!");
      return;
    }

    if (this.state.confirmNewPassword !== this.state.newPassword) {
      this.showWarningNotification("New passwords must match!");
      return;
    }

    this.setState({
      showConfirmDialog: true,
      confirmAction: "CHANGE_PASSWORD",
      confirmMessage: "Do you really want to change your password",
    });
  }

  showWarningNotification(message) {
    this.props.showNotification("warning", message);
  }

  invalidateAllActiveSessions() {
    this.setState({
      showConfirmDialog: true,
      confirmAction: "INVALIDATE_SESSIONS",
      confirmMessage:
        "Do you really want to invalidate all active sessions? You will be logout from all of your device!",
    });
  }

  sendInvalidateAllActiveSessions() {
    this.setState({
      isLoading: true,
    });
    identityService.invalidateSessions().then((response) => {
      CookieHelper.remove();
      window.location.href = "/auth/login";
    });
  }

  sendChangedEmail() {
    this.setState({
      isLoading: true,
    });
    identityService
      .changeEmail(this.state.accountInformation.email)
      .then((response) => {
        this.showSuccessNotification("Email was successfully changed");
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          showWarningDialog: true,
          errorMessage: error.response.data.message,
        });
      });
  }

  render() {
    const securityInfo = this.state.securityInfo;
    const lastLoginDate = securityInfo.lastLogin;
    const email = securityInfo.email;
    const passwordUpdated = securityInfo.passwordUpdated;
    const accountCreated = securityInfo.accountCreated;
    const confirmAction = this.state.confirmAction;

    const mobileDesign = this.props.mobileDesign;

    return (
      <div className="account_container">
        {this.state.isLoading ? <div id="loader" /> : null}

        <h2 className="container-title">Security</h2>
        <div className="spacer_div_20"></div>

        <div className="property-container-section">
          <label className="property_title">Last login</label>
          <label className="property_value">
            {lastLoginDate
              ? dateHelper.getHumanDate(new Date(lastLoginDate))
              : "-"}
          </label>
        </div>
        <div className="property-container-section">
          <label className="property_title">Last password change</label>
          <label className="property_value">
            {passwordUpdated
              ? dateHelper.getHumanDate(new Date(passwordUpdated))
              : "-"}
          </label>
        </div>

        <div className="property-container-section">
          <label className="property_title">Account created</label>
          <label className="property_value">
            {accountCreated
              ? dateHelper.getHumanDate(new Date(accountCreated))
              : "-"}
          </label>
        </div>

        <br />
        <div className="section">
          <h3 className="section-title">Account email</h3>
          <div className="spacer_div_20"></div>
          <p className="message">
            The email address associated with your account
          </p>
          <div className="spacer_div_40"></div>
          <div className="property-container">
            <label className="property_title">Email</label>
            <input
              type="email"
              className="setting-input-box"
              value={email}
              onChange={this.onChangeEmail}
            />
          </div>
          <div className="spacer_div_40"></div>
          <button className="setting-button" onClick={this.changeEmail}>
            Change email
          </button>
        </div>

        <div className="section">
          <h3 className="section-title">Password</h3>

          <div className="spacer_div_20"></div>
          <p className="message">
            Set your new login password. Please note, that this action will
            logout you from any device.
          </p>
          <div className="spacer_div_40"></div>

          <div className="property-container">
            <label className="property_title">Old password</label>

            <input
              type="password"
              id="password"
              className="setting-input-box"
              value={this.state.oldPassword}
              onChange={this.onChangePassword}
            />
          </div>

          {mobileDesign === false ? <br /> : null}

          <div className="spacer_div_20"></div>

          <div className="property-container">
            <label className="property_title">New password</label>

            <input
              type="password"
              id="new_password"
              className="setting-input-box"
              value={this.state.newPassword}
              onChange={this.onChangeNewPassword}
            />
          </div>
          {mobileDesign ? <div className="spacer_div_20"></div> : null}

          <div className="property-container">
            <label className="property_title">Confirm new password</label>

            <input
              type="password"
              id="confirm_new_password"
              className="setting-input-box"
              value={this.state.confirmNewPassword}
              onChange={this.onChangeConfirmNewPassword}
            />
          </div>
          <div className="spacer_div_40"></div>

          <button className="setting-button" onClick={this.changePassword}>
            Change password
          </button>
        </div>

        <div className="section">
          <h3 className="section-title">Logout from all devices</h3>
          <div className="spacer_div_20"></div>
          <p className="message">
            Please note that this action will logout your account from all of
            devices.
          </p>
          <div className="spacer_div_40"></div>
          <button
            className="setting-button"
            onClick={this.invalidateAllActiveSessions}
          >
            Invalidate active sessions
          </button>
        </div>

        {this.state.showSuccessDialog ? (
          <SuccessDialog
            message={this.state.successMessage}
            onClose={this.onCloseSuccessDialog}
          />
        ) : null}

        {this.state.showConfirmDialog ? (
          <ConfirmDialog
            message={this.state.confirmMessage}
            onClose={this.onCloseConfirmDialog}
            onConfirm={
              confirmAction === "CHANGE_EMAIL"
                ? this.sendChangedEmail
                : confirmAction == "CHANGE_PASSWORD"
                ? this.sendChangePassword
                : confirmAction == "INVALIDATE_SESSIONS"
                ? this.sendInvalidateAllActiveSessions
                : null
            }
          />
        ) : null}

        {this.state.showWarningDialog ? (
          <WarningDialog
            message={this.state.errorMessage}
            onClose={this.onCloseWarningDialog}
          />
        ) : null}
      </div>
    );
  }
}
