import React, { Component } from "react";
import CookieHelper from "../../CookieHelper";
import methods from "../../methods";
import reportVisitService from "../../reporting-visit/report-visit.service";
import identityService from "../../services/identity.service";
import WarningDialog from "../general/dialog/warning-modal-dialog.component";
import Footer from "../general/footer/component/footer.component";
import AppIcon from "../../icons/app-icon-full.png";
import { Link } from "react-router-dom";

export default class AuthLogin extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onCloseWarningDialog = this.onCloseWarningDialog.bind(this);

    this.state = {
      email: "",
      password: "",
      errorMessage: "",
      isLoading: false,
      showWarningDialog: false,
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  login() {
    if (
      this.state.email.length === 0 ||
      !methods.validateEmail(this.state.email)
    ) {
      this.setState({
        showWarningDialog: true,
        errorMessage: "For login to app, you must enter valid email!",
      });
      return;
    }

    if (this.state.password.length === 0) {
      this.setState({
        showWarningDialog: true,
        errorMessage: "For login to app, you must enter password!",
      });
      return;
    }

    this.setState({
      isLoading: true,
    });

    identityService
      .login(this.state.email, this.state.password)
      .then((response) => {
        CookieHelper.create(response.data.session);
        let params = window.location.search;
        if (params.includes("?appName")) {
          window.location.href =
            "https://" + params.replace("?appName=", "") + ".davidsimak.dev/";
        } else {
          window.location.href = "/account";
        }
      })
      .catch((e) => {
        console.log(e)
        this.setState({
          isLoading: false,
          showWarningDialog: true,
          errorMessage: e.response.data.message,
        });
      });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  componentDidMount() {
    document.title = "Identity | Welcome back!";

    reportVisitService.report(window.location.pathname, document.title);

    if (CookieHelper.isInBrowser()) {
      window.location.href = "/account";
    }
  }

  onCloseWarningDialog() {
    this.setState({
      errorMessage: "",
      showWarningDialog: false,
    });
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? <div id="loader" /> : null}

        <div className="auth-app-header">
          <img className="auth-app-icon" src={AppIcon} />
          <h1 className="auth-app-title">Identity</h1>
        </div>

        <div id="page_content_wrapper" className="page_content_wrapper">
          <div className="auth_form">
            <h2 className="title_label">Welcome back!</h2>
            <p className="title_des">Please login to your account</p>
            <div className="spacer_div_40"></div>
            <label className="property_title">Email</label>
            <input
              type="email"
              className="input_box"
              value={this.state.email}
              onChange={this.onChangeEmail}
            />
            <div className="spacer_div_20"></div>
            <label className="property_title">Password</label>
            <input
              type="password"
              className="input_box"
              value={this.state.password}
              onChange={this.onChangePassword}
            />

            <div className="spacer_div_40"></div>

            <button className="auth_button" onClick={this.login}>
              Login
            </button>

            <div className="spacer_div_40"></div>

            <Link className="forgot_password" to="/auth/reset-password">
              Forgot your password?
            </Link>

            <div className="spacer_div_80"></div>

            <label className="register_label">
              Don't have an account?{" "}
              <Link to="/auth/register">Register now</Link>
            </label>
          </div>
        </div>

        <Footer />

        {this.state.showWarningDialog ? (
          <WarningDialog
            message={this.state.errorMessage}
            onClose={this.onCloseWarningDialog}
          />
        ) : null}
      </div>
    );
  }
}
