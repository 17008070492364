import http from "./http-common-reporting";
import { v4 as uuidv4 } from "uuid";

let visitorId;

class ReportVisitService {
  report(path, title) {
    // if (!visitorId) {
    //   visitorId = uuidv4();
    // }

    // const data = {
    //   id: "ef8b020e-c708-47bd-9d6e-a00ba014d922",
    //   path: path,
    //   pageName: title,
    //   visitorId: visitorId,
    // };
    // http.post("/", data);
  }
}

export default new ReportVisitService();
