import React, { Component } from "react";

import "../css/header-mobile.css";
import AppIcon from "../../../../icons/app-icon-full.png";

export default class HeaderMobile extends Component {
  
  render() {
    
    return (
      <div className="header-mobile">
        <img className="header-mobile-app-icon" src={AppIcon} />
        <h1 className="header-mobile-title">Identity</h1>
      </div>
    );
  }
}
