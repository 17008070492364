import http from "../util/http-common";

class IdentitySidebarService {
  getSidebarProfileInfo() {
    return http.get("/sidebar/profile-info");
  }

  logoutUser() {
    return http.put("/sidebar/logout");
  }
}

export default new IdentitySidebarService();
